<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {translation, useTranslationStore} from "~/plugins/store/translations";
import type {TranslationList} from "~/types/Utils";
import {useSystemStore} from "~/plugins/store/system";
import {useAuthStore} from "~/plugins/store/auth";
import {recaptcha} from "~/composables/recaptcha";
import {useReCaptcha} from "vue-recaptcha-v3";

export default defineComponent({
  setup() {
    const translationStore = useTranslationStore();
    const translationList = ref<TranslationList>({});
    const authStore = useAuthStore();
    const accessToken = authStore.getAccessToken();
    const isComponentLoaded = ref(false);
    const systemStore = useSystemStore();
    const recaptchaInstance = useReCaptcha();

    onMounted(async () => {
      translationList.value = await translationStore.getTranslations('ro', 'dashboard');
      isComponentLoaded.value = true;
    });

    const onCookieConsentAccepted = async () => {
      const token = await recaptcha(recaptchaInstance);
      await systemStore.storeCookieConsent(true, accessToken, token);
    }

    return {
      translationList,
      translation,
      systemStore,
      onCookieConsentAccepted,
      isComponentLoaded
    };
  }
});
</script>

<template>
  <div class="cookie-container" v-show="!systemStore.isCookieConsentAccepted && isComponentLoaded">
    <p class="cookie-text">
      {{
        translation(translationList.cookie_consent_description_1, 'Pentru scopuri precum afișarea de conținut personalizat, folosim module cookie sau tehnologii similare. Apăsând Accept, ești de acord să permiți colectarea de informații prin cookie-uri sau tehnologii similare. Află in sectiunea ')
      }}
      <NuxtLink class="text-surface" to="/info/privacy-policy">
        {{ translation(translationList.privacy_policy, 'Politica de confidentialitate') }}
      </NuxtLink>
      {{
        translation(translationList.cookie_consent_description_2, '  mai multe despre cookie-uri, inclusiv despre posibilitatea retragerii acordului.')
      }}
    </p>
    <div class="agree">
      <v-btn
          block
          class="block-on-mobile"
          color="primary"
          size="x-large"
          flat
          rounded="0"
          @click="onCookieConsentAccepted"
      >
        {{ translation(translationList.accept, 'Accept') }}
      </v-btn>
    </div>
  </div>
</template>

<style scoped>

</style>